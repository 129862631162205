<template>
  <div class="div-nav1">
      <div class="box-left">
          <div class="left-box">
              <div class="boxtitle">
                  近百人专业服务团队，遍布全国经济大区一对一服务更快捷!
              </div>
              <div class="boxtext">
                  12 年行业经验，600 资深团队人员，精通政策与流程了解法律与法规，合理建议归属注册类型与注册地点，充分理解客户的各种注册公司需求，无论您是刚起步的创业者，还是纳税大户，无论您是
                  启动资金有困难，还是紧急注册公司，卓翔都能助力一臂之力!
              </div>
          </div>
      </div>
      <div class="box-right">
          <div class="rightbox">
              <div class="rightitme">
                <img src="../../assets/homepage/zxcw/bot21.png" alt="">
                <div class="itmecon">
                    <div class="conttitlt">
                        <span class="tbig">12年</span><span class="tbigt">品牌历程</span>
                    </div>
                    <div class="context">
                        公司专注于工商财税服务，将为你的企业品牌进行整体的规划努力为您打造坚实品牌和财税基础
                    </div>
                </div>
              </div>
              <div class="rightitme">
                <img src="../../assets/homepage/zxcw/bot21.png" alt="">
                <div class="itmecon">
                    <div class="conttitlt">
                        <span class="tbig">600+</span><span class="tbigt">专业精英</span>
                    </div>
                    <div class="context">
                        专业精英 600+，服务客户 30000+，服务经验 12 年全心全意为全国创业者提供优质服务
                    </div>
                </div>
              </div>
              <div class="rightitme">
                <img src="../../assets/homepage/zxcw/bot21.png" alt="">
                <div class="itmecon">
                    <div class="conttitlt">
                        <span class="tbig">1000+</span><span class="tbigt">品牌顾客</span>
                    </div>
                    <div class="context">
                        卓翔承诺用心服务好每位客户，客户的满意，就是我们的工作标准目前已在深圳、西安、成都等多个城市设立直营公司，服务范围覆盖多个城市
                    </div>
                </div>
              </div>
              <div class="rightitme">
                <img src="../../assets/homepage/zxcw/bot21.png" alt="">
                <div class="itmecon">
                    <div class="conttitlt">
                        <span class="tbig">30000+</span><span class="tbigt">累计服务客户</span>
                    </div>
                    <div class="context">
                        深耕企业服务领域 12 年，以工商注册、记账报税为基础，致力于为创业者提供最优质的服务，案计服务品牌顾客超 1万家，累计服务客户超3 万家
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style scoped>
.div-nav1 {
  width: 100%;
  height: 750px;
  /* background: #ffffff; */
  background-image: url('../../assets/homepage/zxcw/公司介绍底图.png');
  background-size: 100% 100%;
  /* margin-bottom: 20px; */
  /* margin: 0 auto; */
  /* box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1); */
  /* border-radius: 51px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 .div-nav1 .box-left{
    width: 50%;
    height: 500px;
    /* margin: 0 auto; */
    background-color: #aa0000;
    position: relative;
}
 .div-nav1 .box-left .left-box{
    max-width: 700px;
    min-width: 600px;
    width: 80%;
    height: 400px;
    /* margin: 0 auto; */
    background-color: #fff;
    position: absolute;
    top: 50px;
    right: -150px;
    /* padding: 40px; */
}
 .div-nav1 .box-left .left-box .boxtitle{
    max-width: 550px;
    width: 100%;
    height: 60px;
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    padding-top: 40px;
    padding-left: 20px;
    /* margin-top: 40px; */
}
 .div-nav1 .box-left .left-box .boxtext{
    max-width: 550px;
    width: 100%;
    height: 60px;
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    padding-top: 40px;
    padding-left: 20px;
    line-height: 40px;
}
 .div-nav1 .box-right{
    width: 50%;
    height: 500px;
    /* margin: 0 auto; */
    background-color: #000;
}
 .div-nav1 .box-right .rightbox{
    max-width: 700px;
    width: 100%;
    height: 400px;
    margin-top: 50px;
    /* margin-left: 10px; */
    /* border: 1px red solid; */
}
 .div-nav1 .box-right .rightbox .rightitme{
     width: 550px;
     height: 100px;
     margin-left: 150px;
     text-align: left;
     display: flex;
     justify-content: flex-start;
 }
  .div-nav1 .box-right .rightbox .rightitme .itmecon{
      width: 400px;
      height: 100px;
  }
  .div-nav1 .box-right .rightbox .rightitme .itmecon .conttitlt{
      width: 100%;
      height: 40px;
      color: #fff;
      padding-top: 20px;
  }
  .div-nav1 .box-right .rightbox .rightitme .itmecon .conttitlt .tbig{
      font-size: 30px;
      margin-right: 10px;
  }
  .div-nav1 .box-right .rightbox .rightitme .itmecon  .context{
      width: 350px;
      text-align: left;
      font-size: 12px;
      color: #fff;
  }
</style>
<script>
export default {
  name: "indexitme1",
  methods:{
      getshua(){
          // window.location.replace(location.href);
          console.log(123)
          this.$route.path('/shuiwuzouhua')
      }
  }
};
</script>
