<template>
  <div class="div-nav1">
      <div class="div-box">
          <div class="box-top">
              <div class="topitme1 topitme">
                  公司注册
              </div>
              <div class="topitme">
                  财税代理
              </div>
              <div class="topitme">
                  知识产权
              </div>
              <div class="topitme">
                  资质办理
              </div>
          </div>
          <div class="box-bot">
              <div class="botleft">
                  <div class="leftbox">
                      <span>公司工商</span>
                      <span class="boximg">专业可靠</span>
                      <!-- <img src="../../assets/homepage/zxcw/专业可靠.png" alt=""> -->
                  </div>
                  <p>为您创业提供指南</p>
                  <p>让您创业无忧</p>
              </div>
              <div class="botright">
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
                  <div class="rightbox">
                      <div class="boxtitle">
                          <span>公司注册</span>
                          <img src="../../assets/homepage/zxcw/0元拿执照开公司.png"  alt="" srcset="">
                      </div>
                      <p>极速注册，让您成功快人一步</p>
                      <div class="boxbq">
                          <span>满足各类公司注册所需</span>
                          <span>极速</span>
                          <span>省心</span>
                      </div>
                      <div class="boxbnt">
                          <div class="bntleft">
                              获取价格表
                          </div>
                          <div class="bntright">
                              在线咨询
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style scoped>
.div-nav1 {
  width: 100%;
  height: 600px;
  background: #ffffff;
  margin-bottom: 20px;
  /* margin: 0 auto; */
  /* box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1); */
  /* border-radius: 51px; */
}
.div-nav1 .div-box{
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* border:1px red solid; */
    padding: 40px;
}
.div-nav1 .div-box .box-top{
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    /* border: 1px green solid; */
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 60px;
}
.div-nav1 .div-box .box-top .topitme{
    width: 24.5%;
    height: 100%;
    background-color: #ccc;
    color: #000
}
.div-nav1 .div-box .box-top .topitme1{
    width: 24.5%;
    height: 100%;
    background-color: #A9092C;
    color: #fff;
}
.div-nav1 .div-box .box-top .topitme:hover{
    cursor: pointer;
    background-color: #A9092C;
    color: #fff;
}
.div-nav1 .div-box .box-bot{
    width: 100%;
    height: 480px;
    /* border: 1px green solid; */
    background-image: url('../../assets/homepage/zxcw/服务类型底图.png');
    display: flex;
    justify-content: flex-start;
}
.div-nav1 .div-box .box-bot .botleft{
    width: 230px;
    height: 100%;
    /* border: 1px red solid; */
    text-align: left;
}
.div-nav1 .div-box .box-bot .botleft p{
    margin: 5px 20px;
}
.div-nav1 .div-box .box-bot .botleft .leftbox{
    width: 190px;
    /* height: 80px; */
    /* border: 1px red solid; */
    margin-left: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
}
.div-nav1 .div-box .box-bot .botleft .leftbox .boximg{
    display: inline-block;
    height: 16px;
    line-height: 16pxpx;
    color: #ff6633;
    font-size: 8px;
    font-family: 300;
    margin: 5px 10px;
    border: 1px #ff6633 solid;
    padding: 0px 10px;
}
.div-nav1 .div-box .box-bot .botright{
    max-width: 970px;
    width: 100%;
    height: 100%;
    /* border: 1px yellow solid; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.div-nav1 .div-box .box-bot .botright .rightbox{
    width: 280px;
    height: 190px;
    /* border: 1px red solid; */
    padding-top: 40px;
    padding-left: 20px;
    margin-left: 20px;
    text-align: left;
    font-size: 12px;
    box-shadow: 1px 1px 5px 0px rgba(255, 0, 0, 0.1);
}
.div-nav1 .div-box .box-bot .botright .rightbox:hover{
    box-shadow: 3px 3px 15px 0px rgba(255, 0, 0, 0.2);
}
.div-nav1 .div-box .box-bot .botright .rightbox p{
    font-weight: 600;
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxtitle{
    width: 100%;
    height: 30px;
    font-size: 20px;
    font-weight: 800;
    color: #000;
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbq span{
    border: 1px #c3c3c3 solid;
    margin-right: 5px;
    border-radius: 2px;
    font-weight: 300;
    padding: 1px 3px;
    /* color: #c3c3c3; */
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbnt{
    width: 100%;
    height: 60px;
    margin-top: 20px;
    /* border: 1px red solid; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbnt .bntleft{
    width: 110px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
    background-color: red;
    text-align: center;
    margin-right: 40px;
    /* padding: 0px 10px; */
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbnt .bntleft:hover{
    cursor: pointer;
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbnt .bntright{
    width: 110px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    border:1px #000 solid;
    /* background-color: red; */
    text-align: center;
    /* padding: 0px 10px; */
}
.div-nav1 .div-box .box-bot .botright .rightbox .boxbnt .bntright:hover{
    cursor: pointer;
}
</style>
<script>
export default {
  name: "indexitme1",
  methods:{
      getshua(){
          // window.location.replace(location.href);
          console.log(123)
          this.$route.path('/shuiwuzouhua')
      }
  }
};
</script>
