<template>
  <div class="all">
    <div class="all_center">
      <Header></Header>
      <div class="aboutpart2">
        <!-- <div class="aboutpart2-text1">关于我们</div>
        <div class="aboutpart2-text2">欢迎加入我们</div> -->
      </div>
      <div class="aboutpart3">
        <div>当前位置：首页 > 关于我们</div>
      </div>
      <div class="aboutpart4">
        <div class="aboutpart4-div1">
          <div class="aboutpart4-div2">
            <div class="aboutpart4-div2-text1">关于企业</div>
            <div class="aboutpart4-div2-text2">
              深圳市卓翔企业会计代理有限公司，专注对小企业提供中、高端财务支持服务，主要内容包括代理记账、财务报税、纳税筹划、商标及知识产权代理等，成为集财务、商务于一体的多元化企业智囊团。公司2009年创立于深圳，本着“专业、诚信、协作”的服务宗旨，自成立七年来，卓翔不断历练成长，积累了丰富的执业经验。根据中小企业的发展需求特点量身定制解决方案，集科学性、安全性和保密性于一身，通过专业的顾问咨询模式，提供全方位的一站式全程服务，有效解决企业在财税、商标、知识产权方面的后顾之忧，增强企业的核心竞争力。 
            </div>
          </div>
          <div class="aboutpart4-div3">
            <img src="../../src/assets/homepage/蒙版(17).png" />
          </div>
          <div class="aboutpart4-div4">
            <div class="aboutpart4-div4-div1">
              <div class="aboutpart4-div4-div1-div1">
                <p class="aboutpart4-div4-div1-p1">13</p>
              </div>
              <div class="aboutpart4-div4-div1-div2">
                <span>+</span>
                <p>服务经验</p>
              </div>
            </div>
            <div class="aboutpart4-div4-div2">
              <div class="aboutpart4-div4-div1-div1">
                <p class="aboutpart4-div4-div1-p1">6000</p>
              </div>
              <div class="aboutpart4-div4-div1-div2">
                <span>+</span>
                <p>团队人数</p>
              </div>
            </div>
            <div class="aboutpart4-div4-div2">
              <div class="aboutpart4-div4-div1-div1">
                <p class="aboutpart4-div4-div1-p1">1000</p>
              </div>
              <div class="aboutpart4-div4-div1-div2">
                <span>+</span>
                <p>品牌用户</p>
              </div>
            </div>
            <div class="aboutpart4-div4-div2">
              <div class="aboutpart4-div4-div1-div1">
                <p class="aboutpart4-div4-div1-p1">30000</p>
              </div>
              <div class="aboutpart4-div4-div1-div2">
                <span>+</span>
                <p>累计服务客户</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutpart5">
        <div class="aboutpart5-div1">
          <div class="aboutpart5-div1-text1">企业文化</div>
          <div class="aboutpart5-div1-div">
            <div class="aboutpart5-div1-div-div1">
              <div class="aboutpart5-div1-div-div1-div1">
                <img src="../../src/assets/homepage/编组 5.png" />
              </div>
              <div class="aboutpart5-div1-div-div1-text1">企业价值观</div>
              <div class="aboutpart5-div1-div-div1-text2">
                以人为本<br />服务至上
              </div>
            </div>
            <div class="aboutpart5-div1-div2">
              <div class="aboutpart5-div1-div-div1">
                <div class="aboutpart5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 10.png" />
                </div>
                <div class="aboutpart5-div1-div-div1-text1">企业理念</div>
                <div class="aboutpart5-div1-div-div1-text2">
                  客户第一<br />以奋斗者为本<br />持续不断的精进
                </div>
              </div>
            </div>
            <div class="aboutpart5-div1-div2">
              <div class="aboutpart5-div1-div-div1">
                <div class="aboutpart5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 14.png" />
                </div>
                <div class="aboutpart5-div1-div-div1-text1">企业目标</div>
                <div class="aboutpart5-div1-div-div1-text2">
                  科技创新引领产业发展 产教融合培育技能人才
                </div>
              </div>
            </div>
            <div class="aboutpart5-div1-div2">
              <div class="aboutpart5-div1-div-div1">
                <div class="aboutpart5-div1-div-div1-div1">
                  <img src="../../src/assets/homepage/编组 15.png" />
                </div>
                <div class="aboutpart5-div1-div-div1-text1">企业目标</div>
                <div class="aboutpart5-div1-div-div1-text2">
                  成为中小企业首选的商务服务提供商
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutpart6">
        <div class="aboutpart6-div">
          <div class="aboutpart6-text1">品牌文化</div>
          <div class="aboutpart6-div2">
            <div class="aboutpart6-div2-text1">
              卓翔会计代理的创立，源于一个“卓越领先、翱翔梦想”的品牌初心。创始团队由一群在知名企业任职多年、经验丰富的优秀行业精英组成，作为创业路上的同行者，卓翔深谙客户需求，想客户所想，急客户所急，以合作双赢为己任，打造值得全心信赖、足可托付的财务代理顾问定位，致力为广大亟需发展的中小企业及创业者提供出色服务。秉承“卓越诚信，服务至上” 的经营理念和服务原则，凭借专业金融知识、丰富实战经验和高效专业服务，卓翔已与众多企业进行合作，帮助客户规范财务工作，提高财务部门的工作效率，提升企业内部控制管理能力及企业对于资金的运作能力等，赢得了数以万计客户的由衷信赖和全心托付。●企业核心价值观：以人为本，服务至上。对员工实行人本关怀，让每一个员工能感受到企业的关爱，更好的提升主观能动性，打造人本价值。
              ●企业发展理念：客户第一，以奋斗者为本，持续不断的精进。
              ●企业战略目标：成为中小企业首选的商务服务提供商。
              总结必须有情况的概述和叙述，有的比较简单，有的比较详细。这部分内容主要是对工作的主客观条件、有利和不利条件以及工作的环境和基础等进行分析。
            </div>
            <div class="aboutpart6-div3">
              <div class="aboutpart6-div3-text"></div>
              <div class="aboutpart6-div3-img">
                <!-- <img src="../../src/assets/homepage/杨刚.png" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="aboutpart7">
        <div class="aboutpart7-div">
          <div class="aboutpart7-div-text">发展历程</div>
          
          <div class="aboutpart7-div2">
            <div class="aboutpart7-div2-img1">
              <img src="../../src/assets/homepage/左翻(1).png" />
            </div>
            <div class="aboutpart7-div2-img2">
              <img src="../../src/assets/homepage/右翻(2).png" />
            </div>
            <div class="aboutpart7-div2-div1">
              <div class="dianxian">
                <div class="dian"></div>
                <div class="xian"></div>
              </div>
              <div class="text">
                <p class="aboutpart7-div2-div1-text1">2010</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="aboutpart8">
        <div class="aboutpart8-div">
          <div class="aboutpart8-div-text">办公环境</div>
          <div class="aboutpart8-div2">
            <div class="aboutpart8-div2-div1">
              <img src="../../src/assets/homepage/2.png" />
            </div>
            <div class="aboutpart8-div2-div2">
              <img src="../../src/assets/homepage/3.png" />
              <img
                src="../../src/assets/homepage/4.png"
                style="margin-left: 13px"
              />
              <img
                src="../../src/assets/homepage/6.png"
                style="margin-top: 9px"
              />
              <img
                src="../../src/assets/homepage/7.png"
                style="margin-left: 11px"
              />
            </div>
          </div>
        </div>
      </div> -->
      <Bottom2></Bottom2>
      <Nav></Nav>
    </div>
  </div>
</template>
<style scoped>
@import url("../assets/style2.css");
</style>
<script>
import Nav from "../components/nav1/bottomnav.vue";
import Header from "../components/nav1/index2.vue";
import Bottom2 from "../components/nav1/bottomnav2.vue";
export default {
  components: {
    Header,
    Nav,
    Bottom2
  },
};
</script>
