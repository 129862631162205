<template>
  <div class="div-bot2">
      <div class="bot-itme">
          <div class="itme-box">
              <img class="boximg" src="../../assets/homepage/zxcw/bot21.png" alt="品牌历程" srcset="">
              <div class="boxtext">
                  <div class="texttitle">
                      12年
                  </div>
                  <div class="textcon">
                      品牌历程
                  </div>
              </div>
          </div>
          <div class="itme-box">
              <img class="boximg" src="../../assets/homepage/zxcw/bot22.png" alt="品牌历程" srcset="">
              <div class="boxtext">
                  <div class="texttitle">
                      600+
                  </div>
                  <div class="textcon">
                      专业精英
                  </div>
              </div>
          </div>
          <div class="itme-box">
              <img class="boximg" src="../../assets/homepage/zxcw/bot23.png" alt="品牌历程" srcset="">
              <div class="boxtext">
                  <div class="texttitle">
                      1000+
                  </div>
                  <div class="textcon">
                      品牌顾客
                  </div>
              </div>
          </div>
          <div class="itme-box">
              <img class="boximg" src="../../assets/homepage/zxcw/bot24.png" alt="品牌历程" srcset="">
              <div class="boxtext">
                  <div class="texttitle">
                      30000+
                  </div>
                  <div class="textcon">
                      累计服务客户
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style scoped>
    .div-bot2{
        width: 100%;
        height: 130px;
        background-color:#A9092C;
    }
    .div-bot2 .bot-itme{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .div-bot2 .bot-itme .itme-box{
        width: 25%;
        height: 100%;
        /* border: 1px green solid; */
        display: flex;
        justify-content: ;
        align-items: center;
    }
    .div-bot2 .bot-itme .itme-box .boxtext{
        width: 75%;
        height: 100%;
        text-align: left;
        color: #fff;
        font-size: 16px;
        /* margin: 0 auto; */
    }
    .div-bot2 .bot-itme .itme-box .boxtext .texttitle{
        width: 100%;
        font-size: 45px;
        font-weight: 300;
        margin-top: 20px;
    }
</style>
<script>
export default {
  name: "Bottom",
};
</script>