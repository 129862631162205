<template>
    <div class="all">
        <Nav class="nav_1"></Nav>
        <img src="../assets/homepage/zxcw/首页1.png" class="imge-back" />
        <div class="text1">
        </div>
        <div class="all2">
            <div>
                <p class="text2">最新资讯</p>
                <!-- <ul class="nav">
                    <li><a href=""> 集团新闻</a></li>
                    <li><a href=""> 媒体报道</a></li>
                    <li><a href=""> 行业资讯</a></li>
                </ul> -->
            </div>

            <div class="all_left">
                <img src="../assets/homepage/出口关税.jpg" />
                <div class="all_left_div">
                    <p class="all_left_text1">定了，这些企业可退出口关税！</p>
                    <p class="all_left_text2">2023-02-23</p>
                    <p class="all_left_text3">
                        税务局关于跨境电商出口退税最新公告：1月30日起跨境电子商务...
                    </p>
                    <br>
                </div>
            </div>
            <div class="all_right">
                <div class="all_right_div1" v-for="(item, index) in ObjectList" :key="index">
                    <p class="all_right_text1">{{ item.title }}</p>
                    <p class="all_right_text2">{{ item.date }}</p>
                    <p class="all_right_text3">{{ item.content }}</p>
                </div>
            </div>
            <!-- <div class="all3">
                <div class="all3_nav">
                    <div class="all3_nav_div1">公司注册</div>
                    <div class="all3_nav_div2"><router-link to="/gongsizhuce">更多 ></router-link></div>
                </div>
                <div class="tall3_div1">
                    <div class="tall3_div1_div">
                        <Kuang1></Kuang1>
                    </div>
                    <div class="tall3_div1_div">
                        <Kuang2></Kuang2>
                    </div>
                    <div class="tall3_div1_div">
                        <Kuang3></Kuang3>
                    </div>
                    <div class="tall3_div1_div">
                        <Kuang4></Kuang4>
                    </div>
                </div>
                <div class="all3_div2">
                    <img src="../assets/homepage/zxcw/税审报告.jpg" class="all3_img2" style="width: 384px;height: 546px;" />
                </div>
            </div> -->
            <!-- <div class="all4">
                <div class="all4_div1">
                    <p class="all4_p1">关于企业</p>
                    <p class="all4_p2">
                        深圳市卓翔企业会计代理有限公司，专注对小企业提供中、高端财务支持服务，主要内容包括代理记账、财务报税、纳税筹划、商标及知识产权代理等，成为集财务、商务于一体的多元化企业智囊团。公司2009年创立于深圳，本着“专业、诚信、协作”的服务宗旨，自成立七年来，卓翔不断历练成长，积累了丰富的执业经验。根据中小企业的发展需求特点量身定制解决方案，集科学性、安全性和保密性于一身，通过专业的顾问咨询模式，提供全方位的一站式全程服务，有效解决企业在财税、商标、知识产权方面的后顾之忧，增强企业的核心竞争力。
                    </p>
                </div>
                <div class="all4_div2">
                    <img src="../assets/homepage/zxcw/ywtype.jpg" class="all4_img" />
                </div>
                <div class="all4_div">
                    <div class="all4_div4">
                        <p class="all4_div4_p1">14 </p>
                        <p class="all4_div4_p2">+</p>
                        <p class="all4_div4_p3">服务经验</p>
                    </div>

                    <div class="all4_div5">
                        <p class="all4_div4_p1">30000</p>
                        <p class="all4_div4_p2">+</p>
                        <p class="all4_div4_p3">累计客户数量</p>
                    </div>
                    <div class="all4_div6">
                        <p class="all4_div4_p1">1000</p>
                        <p class="all4_div4_p4">+</p>
                        <p class="all4_div4_p5">品牌客户</p>
                    </div>
                    <div class="all4_div7">
                        <p class="all4_div4_p1">600</p>
                        <p class="all4_div4_p2">+</p>
                        <p class="all4_div4_p3">团队人数</p>
                    </div>
                </div>
            </div> -->
            <indexitme1></indexitme1>
            <indexitme2></indexitme2>
            <indexitme3></indexitme3>
            <!-- <Bottom2 class="nav_2"></Bottom2> -->
            <Bottom class="nav_2"></Bottom>
        </div>
    </div>
</template>
<script>
    import Nav from "./nav1/index2.vue";
    import Bottom from "./nav1/bottomnav.vue";
    // import Bottom2 from "./nav1/bottomnav2.vue";
    import indexitme1 from "./nav1/indexitme1.vue";
    import indexitme2 from "./nav1/indexitme2.vue";
    import indexitme3 from "./nav1/indexitme3.vue";
    // import Kuang1 from "./kuang1.vue";
    // import Kuang2 from "./kuang2.vue";
    // import Kuang3 from "./kuang3.vue";
    // import Kuang4 from "./kuang4.vue";
    export default {
        name: "HelloWorld",
        props: {},
        components: {
            Nav,
            Bottom,
            // Bottom2,
            // Kuang1,
            // Kuang2,
            // Kuang3,
            // Kuang4,
            indexitme1,
            indexitme2,
            indexitme3
        },
        data() {
            return {
                ObjectList: [{
                        title: "干货 | 节税不是偷税，这16个税收常识，要记牢",
                        date: "2019-11-19",
                        content: "1.小规模纳税人月(季)免征增值税标准是多少? 自2019年1月1日起，小规模纳税人发生增值税应税销售行为...",
                    },
                    {
                        title: "如何实时查询报税、做账进度及财务报表",
                        date: "2019-08-13",
                        content: "卓翔财务：让企业财务数据实时查看，报税进度、做账进度、报表，随时查看，随时沟通。让财务不再是企业的锁链，而是通往成功...",
                    },
                    {
                        title: "企业小主们，关于商标注册你知道多少？",
                        date: "2019-10-15",
                        content: "很多人没有商标注册的意识，觉得先用了产品名称，那这个名称就是属于自己的。但不是这样，我们国家实行的是...",
                    },
                ],
                imgList: [{
                        img1: require("../assets/homepage/zxcw/001.png"),
                        img2: require("../assets/homepage/矩形.png"),
                        title: "金融产业",
                        nav: "融资租赁 | 债券融资 | 信托 | 产业基金",
                    },
                    {
                        img1: require("../assets/homepage/zxcw/002.png"),
                        // img:  require("../assets/homepage/矩形(5).png"),
                        img2: require("../assets/homepage/矩形.png"),
                        // img2: "../assets/homepage/矩形.png",
                        title: "科技产业",
                        nav: "教育信息化  |  云技术与应用  |  城市信息化",
                    },
                    {
                        img1: require("../assets/homepage/zxcw/003.png"),
                        // img: "../assets/homepage/矩形(5).png ",
                        img2: require("../assets/homepage/矩形.png"),
                        // img2: "../assets/homepage/矩形.png",
                        title: "电商产业",
                        nav: "网店运营 ｜ 创业孵化 ｜ 垂直电商",
                    },
                    {
                        img1: require("../assets/homepage/zxcw/004.png"),
                        img2: require("../assets/homepage/矩形.png"),
                        // img: "../assets/homepage/矩形(7).png ",
                        // img2: "../assets/homepage/矩形.png",
                        title: "幼教产业",
                        nav: "共建专业  |  订单式合作  |  共同项目研发  |  共建学院",
                    },
                ],
                // arrList: [
                //   { P1: 30, P2: "+", P3: "全国校区" },
                //   { P1: 10, P2: "+", P3: "覆盖城市" },
                //   { P1: 7000, P2: "+", P3: "优秀讲师" },
                //   { P1: 80, P2: "万", P3: "服务学生" },
                // ],
            };
        },
    };
</script>
<style scoped="scoped">
    @import url("../assets/style.css");
</style>
