<template>
  <div class="div-nav1">
      <div class="div-box">
          <div class="box-title">
              卓翔金牌服务保障
          </div>
          <div class="box-itme">
              <div class="itmetop itmet itmetop1">
                  <div class="topimg">
                      <img src="../../assets/homepage/zxcw/a1.png" alt="" srcset="">
                  </div>
                  <div class="toptitle">
                      品牌背书
                  </div>
                  <div class="topcon">
                      <p>政府扶持公共平台</p>
                      <p>市级开发区入驻</p>
                  </div>
              </div>
              <div class="itmetop itmet">
                  <div class="topimg">
                      <img src="../../assets/homepage/zxcw/a2.png" alt="" srcset="">
                  </div>
                  <div class="toptitle">
                      12年品牌里程
                  </div>
                  <div class="topcon">
                      <p>屹立12年的财税品牌</p>
                      <p>行业权威，佳誉户晓润</p>
                  </div>
              </div>
              <div class="itmetop itmet">
                  <div class="topimg">
                      <img src="../../assets/homepage/zxcw/a3.png" alt="" srcset="">
                  </div>
                  <div class="toptitle">
                      30000+企业口碑好评
                  </div>
                  <div class="topcon">
                      <p>打造体系化的工商财税服务</p>
                      <p>让老板从琐事物中脱身</p>
                  </div>
              </div>
              <div class="itmetop itmet">
                  <div class="topimg">
                      <img src="../../assets/homepage/zxcw/a4.png" alt="" srcset="">
                  </div>
                  <div class="toptitle">
                      600+专业服务人员
                  </div>
                  <div class="topcon">
                      <p>打造行胜于言的精锐团队</p>
                      <p>用匠心为创业者服务</p>
                  </div>
              </div>
              <div class="itmebot itmet">
                  <div class="botleft">
                      <p class="bottitle">多地区覆盖</p>
                      <div class="bottext">
                          分别成立四川、成都2大分公司
                          为创业者提供300+产品及服务满足创业者夸地区的不同需求
                      </div>
                  </div>
                  <div class="botright">
                      <img src="../../assets/homepage/zxcw/a5.png" alt="" srcset="">
                  </div>
              </div>
              <div class="itmebot itmet">
                  <div class="botleft">
                      <p class="bottitle">品牌强强联合</p>
                      <div class="bottext">
                          与100余家创业园区强强联合品牌实力保证，让创业安心
                      </div>
                  </div>
                  <div class="botright">
                      <img src="../../assets/homepage/zxcw/a6.png" alt="" srcset="">
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<style scoped>
.div-nav1 {
  width: 100%;
  height: 700px;
  /* background: #ffffff; */
  background-image: url('../../assets/homepage/zxcw/服务保障底图.png');
  background-size: 100% 100%;
  /* margin-bottom: 20px; */
  /* margin: 0 auto; */
  /* box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1); */
  /* border-radius: 51px; */
}
.div-nav1 .div-box{
    max-width: 1200px;
    width: 100%;
    height: 85%;
    margin: 0 auto;
    padding-top: 40px;
    /* border:1px red solid; */
}
.div-nav1 .div-box .box-title{
    width: 100%;
    height: 30px;
    margin: 40px 0px;
    font-size: 28px;
    font-weight: 800;
}
.div-nav1 .div-box .box-itme{
    width: 100%;
    height: 500px;
    /* border: 1px red solid; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* overflow: hidden; */
}
.div-nav1 .div-box .box-itme .itmet{
    border-bottom: 4px #000 solid;
    box-shadow: 1px 1px 5px 0px #ccc;
}
.div-nav1 .div-box .box-itme .itmet:hover{
    cursor: pointer;
    border-bottom: 4px red solid;
    box-shadow: 3px 3px 15px 0px #ccc;
}
.div-nav1 .div-box .box-itme .itmetop{
    max-width: 295px;
    width: 100%;
    height: 300px;
    /* border: 1px green solid; */
    margin: 0 auto;
    padding-top: 20px;
}
.div-nav1 .div-box .box-itme .itmetop1{
    border-bottom: 4px red solid;
}
.div-nav1 .div-box .box-itme .itmetop .topimg{
    width: 90px;
    height: 90px;
    background-color: #ccc;
    border-radius: 60px;
    margin: 0 auto;
}
.div-nav1 .div-box .box-itme .itmetop .topimg img{
    display: inline-block;
    width: 90px;
    height: 90px;
}
.div-nav1 .div-box .box-itme .itmetop .toptitle{
    width: 100%;
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
}
.div-nav1 .div-box .box-itme .itmebot{
    max-width: 590px;
    width: 100%;
    height: 150px;
    /* border: 1px green solid; */
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.div-nav1 .div-box .box-itme .itmebot .botleft{
    width: 280px;
    height: 100%;
    /* border: 1px red solid; */
    /* margin: 10px 0px 10px 20px; */
    text-align: left;
}
.div-nav1 .div-box .box-itme .itmebot .bottitle{
    width: 100%;
    height: 30px;
    /* margin: 0; */
    font-size: 20px;
    font-weight: 800;
}
.div-nav1 .div-box .box-itme .itmebot .bottext{
    width: 180px;
    font-size: 14px;
}
.div-nav1 .div-box .box-itme .itmebot .botright{
    width: 90px;
    height: 90px;
    border-radius: 60px;
    background-color: #ccc;
}
.div-nav1 .div-box .box-itme .itmebot .botright img{
    display: inline-block;
    width: 90px;
    height: 90px;
}
.div-nav1 .div-box .box-itme .topcon {
    margin-top: 20px;
    font-size: 16px;
}
.div-nav1 .div-box .box-itme .topcon p{
    margin: 0;
}
</style>
<script>
export default {
  name: "indexitme1",
  methods:{
      getshua(){
          // window.location.replace(location.href);
          console.log(123)
          this.$route.path('/shuiwuzouhua')
      }
  }
};
</script>
