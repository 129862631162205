import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Home from '../views/Preschool Education.vue'
import about from '../views/About the Group.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // component: () => import(/* webpackChunkName: "about" */ '../views/Preschool Education.vue')
  },
  {
    path: '/about',
    name: 'About the Group ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About the Group.vue')
    component: about
  },
  {
    path: '/shuiwuzouhua',
    name: 'shuiwuzouhua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shuiwuzouhua.vue')
  },
  {
    path: '/other',
    name: 'Contact Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact Us.vue')
  },
  {
    path: '/join',
    name: 'Join Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Join Us.vue')
  },
  {
    path: '/industry',
    name: 'industry',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/financial sector.vue')
  },
  {
    path: '/gongsizhuce',
    name: 'youjiao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Preschool Education.vue')
  },
  {
    path: '/zuceshangbiao',
    name: 'Science and technology sector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Science and technology sector.vue')
  },
  {
    path: '/dianshang',
    name: 'E-commerce sector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/E-commerce sector.vue')
  },
  {
    path: '/zizhibanli',
    name: 'vocational education',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/vocational education.vue')
  },
  {
    path: '/xiangqing',
    name: 'News Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News Details.vue')
  },
  {
    path: '/zhaopin',
    name: 'zhaopinxiangqing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/zhaopinxiangqing.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
